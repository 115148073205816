import { ArrowDownLeftIcon } from '@/ui/svgs/arrow-down-left-icon';
import { ArrowUpRightIcon } from '@/ui/svgs/arrow-up-right';
import { SyncIcon } from '@/ui/svgs/sync-icon';

import { formatTime12Hour } from '@/lib/utils';

import { TransferDirection, TransferState, TransferStatus } from '../types';

export const getIconBasedOnTransactionType = ({
  direction,
  ...props
}: {
  direction: TransferDirection;
  className: string;
}) => {
  switch (direction) {
    case 'INWARD':
      return <ArrowDownLeftIcon {...props} />;
    case 'EXCHANGE':
      return <SyncIcon {...props} />;
    case 'OUTWARD':
      return <ArrowUpRightIcon {...props} />;
    default:
      return <ArrowUpRightIcon {...props} />;
  }
};

export const getTransactionSubtitle = (
  status: TransferStatus,
  transferTimestamp: string,
  tag: string,
) => {
  const formattedTime = formatTime12Hour(transferTimestamp).toLocaleUpperCase();
  const statusLower = status?.toLowerCase() ?? '';
  const tagLower = tag?.toLowerCase() ?? '';

  if (statusLower === TransferStatus.Completed.toLowerCase()) {
    return formattedTime;
  }

  if (statusLower === TransferStatus.Failed.toLowerCase()) {
    const tagClasses = {
      [TransferState.REFUND_IN_PROGRESS.toLowerCase()]:
        'text-feedback-warning-ghost-text',
      [TransferState.FAILED.toLowerCase()]: 'text-feedback-danger-ghost-text',
    };

    const className = tagClasses[tagLower] ?? 'text-content-label-alternative';
    return <span className={className}>{tag}</span>;
  }

  return `${formattedTime} · ${tag}`;
};
