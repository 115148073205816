import {
  HTMLCustomVariantsAwareProps,
  createComponent,
  useTailwindVariants,
} from '@/ui/system';

import { type TextVariants, textVariants } from './text.cx';

export interface TextProps
  extends HTMLCustomVariantsAwareProps<'p', TextVariants> {}

export const useTextVariants = (props: TextProps = {}) =>
  useTailwindVariants(props, textVariants);

/** @deprecated Use `Typography` from KamonaUI instead */
export const Text = createComponent<TextProps>(
  ({ as: Component = 'p', ...props }, ref) => {
    const blueprint = useTextVariants(props);

    return <Component ref={ref} {...blueprint.ownProps} />;
  },
);

Text.displayName = 'Text';
