'use client';

import { useHttpMutation, useHttpQuery } from '@/shared/hooks';
import { type Profile } from '@/shared/types';

export const useGetMyProfileQuery = useHttpQuery<never, Profile>;

export function useGetMyProfile(
  options?: Partial<Parameters<typeof useGetMyProfileQuery>[0]>,
) {
  return useGetMyProfileQuery({
    ...options,
    url: '/profile/v1/profiles/mine',
    queryKey: ['profile'],
  });
}

export const useRequestMyProfileMutation = useHttpMutation<never, Profile>;

export function useRequestMyProfile(
  options?: Partial<Parameters<typeof useRequestMyProfileMutation>[0]>,
) {
  return useRequestMyProfileMutation({
    url: '/profile/v1/profiles/mine',
    isAuthorized: true,
    method: 'GET',
    retry: 3,
    ...options,
  });
}
