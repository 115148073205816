'use client';

import { LoaderModal } from '@kamona/components';
import { useEffect } from 'react';

import { cn } from '@/lib/utils';

export type PageLoaderProps = {
  background?: 'opaque' | 'transparent';
};

export function PageLoader({
  background = 'transparent',
}: Readonly<PageLoaderProps>) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <LoaderModal
      open
      className={{
        backdrop: cn(background === 'opaque' && 'bg-neutral-400'),
        content: 'w-auto',
      }}
      contentProps={{ 'aria-label': 'Loading' }}
    />
  );
}
