import { Column, ColumnProps } from '@kamona/components';

import { cn } from '@/lib/utils';

export function Page({
  container = 'xs',
  className,
  ...props
}: Readonly<
  ColumnProps & {
    container?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  }
>) {
  return (
    <Column
      as="main"
      gap="units-unit40"
      align="center"
      justify="center"
      className={cn(
        'my-units-unit40 md:my-units-unit56 px-units-unit16',
        // Make sure these classes exist in globals.scss
        `container-${container}`,
        className,
      )}
      {...props}
    />
  );
}

export function CommonNavigationPage({
  className,
  ...props
}: Readonly<ColumnProps>) {
  return (
    <Column
      as="main"
      gap="units-unit40"
      className={cn('py-units-unit40', className)}
      {...props}
    />
  );
}
