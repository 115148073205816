import { STORAGE_KEYS } from '@/lib/constants';
import { getCookie, parseJwt } from '@/lib/utils';

import { Profile } from '../types';

export const checkUserLoggedIn = () => {
  const token = getCookie(STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY);
  const decodedToken = token ? parseJwt(token) : undefined;
  return decodedToken?.exp > Date.now() / 1000;
};

export const getUserInitials = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  return `${firstName.charAt(0) ?? ''}${lastName.charAt(0) ?? ''}`;
};

export function capitalizeString(input: string): string {
  if (!input) return '';
  const words = input.trim().split(' ');

  const result = words.map((word) => {
    word = word.toLowerCase();
    word = word.at(0)?.toUpperCase() + word.slice(1);
    return word;
  });

  return result.join(' ');
}

export function formatProfileAddress(address: Profile['address']) {
  return [address.line1, address.line2].filter(Boolean).join(' ');
}
