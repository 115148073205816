import { KycLevel, KycStatus } from '@/shared/types';

import { useProfileContext } from '../providers';
import { useKycStatus } from './use-kyc-status';

export function useKycLevel() {
  const { profile } = useProfileContext();
  const { checkKycStatus } = useKycStatus();

  if (
    profile?.currentKyc?.kycId &&
    checkKycStatus(KycStatus.Verified, KycStatus.Pending)
  ) {
    return profile?.currentKyc.kycId;
  } else if (profile?.currentKyc?.kycId === KycLevel.Level2) {
    return KycLevel.Level1;
  }
  return null;
}
