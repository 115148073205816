'use client';

import { Image, ImageProps } from '@kamona/components';
import { createComponent } from '@kamona/styled-system';
import { useIsScreenUp } from '@kamona/utils-v2';

import { cn } from '@/lib/utils';

export const PageCover = createComponent<ImageProps>(
  ({ alt = '', className, ...props }, ref) => {
    const isDesktop = useIsScreenUp('md');

    const width = isDesktop ? 1098 : 362;
    const height = isDesktop ? 304 : 185;

    return (
      <Image
        ref={ref}
        alt={alt}
        width={width}
        height={height}
        loading="lazy"
        className={{
          ...className,
          image: cn(
            'object-cover',
            isDesktop ? 'aspect-[29/8]' : 'aspect-[2/1]',
            className?.image,
          ),
        }}
        fallbackSrc="/assets/images/checker.png"
        {...props}
      />
    );
  },
);
